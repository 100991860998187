<template>
    <div class="layout-body">
        <!--s: header-->
        <Header/>
        <!--e: header-->

        <!--s: User-->
        <div class="layout-prompt">
            Hello {{ userName }}!
        </div>
        <!--e: User-->

        <!--s: links-->
        <div class="layout-link-box">
            <div class="link-li" :key="key" v-for="(catalogue,key) in catalogueObj">
                <a href="javascript:" :key="index" v-for="(catalogueChildren,index) in catalogue.children" @click="onLink(catalogueChildren.path)">{{ catalogueChildren.name }}</a>
            </div>
        </div>
        <!--e: links-->
    </div>
</template>
<script>
import Catalogue from './catalogue'
export default Catalogue
</script>
<style lang="less">
@import "catalogue";
</style>
