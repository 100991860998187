import Header from '@/components/Header/header.vue'
import { mapState } from "vuex";
import { getMenusBuildService } from '@/service/menu-service'
const Catalogue = {
    name:'Catalogue',
    components:{
        Header
    },
    computed: {
        ...mapState({
            userName: state => state.userCenter.userName,  //获取状态管理用户名称
        }),
    },
    data() {
        return {
            catalogueObj:{}, //目录对象
        }
    },
    created() {
        this.onMenusBuild().then()
    },
    methods: {
        /**
         * 跳转
         * @param link
         */
        onLink(link){
            this.$router.push(link)
        },
        /**
         * 拉取前端所需菜单
         * @returns {Promise<void>}
         */
        async onMenusBuild() {
            try {
                const data = await getMenusBuildService({})
                this.catalogueObj = data

            }catch (error){
                console.log(error)
            }
        }
    },
}
export default Catalogue
